<script lang="ts">
  import { onDestroy } from 'svelte'
  import { type SavedSearch } from 'common/src/types'
  import { state } from '../../store/state'
  import SavedSearchDetails from '../search/saved-search/saved-search-details.svelte'

  let homeSearch: SavedSearch | undefined = undefined

  const unsubscribe = state.subscribeTo('userData.savedSearches.searches', 'subscribe', (searches) => {
    if (!state.experimental.get()) {
      homeSearch = undefined
      return
    }

    homeSearch = searches.find((search) => search.isInHome)
  })

  onDestroy(() => unsubscribe())
</script>

<div class="m-4 w-fit">
  <SavedSearchDetails data={homeSearch} />
</div>
